<template>
  <van-popup v-model:show="visible">
    <div class="common-popup">
      <div class="title">恭喜您获得了{{ name }}</div>
      <div class="tip">请完善中奖信息</div>
      <input v-model="form.receiveUserName" placeholder="收货人姓名" type="text" />
      <input v-model="form.receivePhone" placeholder="联系电话" type="text" />
      <input
        v-model="areasText"
        placeholder="省市区"
        type="text"
        readonly
        @click="areasVisible = true"
      />
      <input v-model="form.receiveAddress" placeholder="详细地址" type="text" />
      <van-button type="primary" color="#f34141" class="btn" @click="submit">提交</van-button>
    </div>
  </van-popup>

  <van-popup v-model:show="areasVisible" position="bottom">
    <v-cascader
      v-model:value="areas"
      :options="areasData"
      title="请选择省市区"
      @select-done="onSelectDone"
    ></v-cascader>
  </van-popup>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { Popup, Button, Toast } from 'vant'
import { getAreasData, exchangePrize } from '@/api/lottery'

export default defineComponent({
  name: 'Exchange',

  components: {
    [Popup.name]: Popup,
    [Button.name]: Button,
  },

  props: {
    id: {
      type: [Number, String],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },

  emits: ['done'],

  setup(props, context) {
    // const { emit } = useContext()
    const visible = ref(false)
    const areasVisible = ref(false)
    const areasData = ref<Recordable[]>([])
    const areas = ref<string[]>([])
    const areasText = ref('')
    const form = ref({
      receiveUserName: '',
      receivePhone: '',
      province: '',
      city: '',
      district: '',
      receiveAddress: '',
    })

    // 获取省市区数据
    const fetchAreas = () => {
      getAreasData()
        .then((res) => {
          const data = res.data.data as Recordable[]
          areasData.value = data
        })
        .catch(() => {})
    }

    const onSelectDone = (payload: { value: string[]; label: string[] }) => {
      areasVisible.value = false
      areasText.value = payload.label.join('-')
      areas.value = payload.value
      form.value.province = payload.value[0]
      form.value.city = payload.value[1]
      form.value.district = payload.value[2]
    }

    const validate = () => {
      if (!form.value.receiveUserName) {
        Toast('请输入收货人姓名')
        return false
      }
      if (!form.value.receivePhone) {
        Toast('请输入联系电话')
        return false
      }
      if (!form.value.province) {
        Toast('请选择省市区')
        return false
      }
      if (!form.value.receiveAddress) {
        Toast('请输入详细地址')
        return false
      }
      return true
    }

    const submit = () => {
      if (validate()) {
        console.log('提交')
        const params = {
          luckyDrawWinningRecordLogId: props.id,
          ...form.value,
        }
        exchangePrize(params)
          .then(() => {
            visible.value = false
            Toast('恭喜，兑奖成功')
            context.emit('done')
            reset()
          })
          .catch(() => {})
      }
    }

    // 重置表单
    const reset = () => {
      form.value = {
        receiveUserName: '',
        receivePhone: '',
        province: '',
        city: '',
        district: '',
        receiveAddress: '',
      }
      areas.value = []
      areasText.value = ''
    }

    fetchAreas()

    return {
      visible,
      areasVisible,
      areasData,
      areas,
      areasText,
      form,
      onSelectDone,
      submit,
    }
  },
})
</script>

<style lang="less" scoped>
.title {
  display: flex;
  justify-content: center;
  align-self: center;
  height: 42px;
  font-size: 30px;
  color: #f34141;
}
.tip {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-bottom: 32px;
  height: 42px;
  font-size: 30px;
  color: #333;
}
input {
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  margin-bottom: 20px;
  padding: 0 14px;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  &::-webkit-input-placeholder {
    font-size: 28px;
    color: #999;
  }
}
.btn {
  width: 250px;
  height: 70px;
}
.common-popup {
  width: 580px;
  padding: 30px;
  border-radius: 8px;
  background: #fff;
  text-align: center;
}
</style>
