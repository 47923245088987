import wx from 'weixin-js-sdk'
import { getWxConfig } from '@/api/member'

// 通过config接口注入权限验证配置
export function configWx(config: WxConfig) {
  ;(wx as any).config({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: config.appId, // 必填，公众号的唯一标识
    timestamp: config.timestamp, // 必填，生成签名的时间戳
    nonceStr: config.nonceStr, // 必填，生成签名的随机串
    signature: config.signature, // 必填，签名
    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的JS接口列表
  })
}

// 通过ready接口处理成功验证
export function onWxReady(): Promise<void> {
  return new Promise((resolve) => {
    ;(wx as any).ready(resolve)
  })
}

// 通过error接口处理失败验证
export function onWxError(): Promise<any> {
  return new Promise((resolve) => {
    ;(wx as any).error((res: any) => resolve(res))
  })
}

interface Options {
  title?: string // 分享标题
  desc?: string // 分享描述
  link?: string // 分享链接
  imgUrl?: string // 分享图片地址
  messageShareSuccess?: () => void // updateAppMessageShareData成功回调
  timelineShareSuccess?: () => void // updateTimelineShareData成功回调
}
// // 前端代码控制的2个config字段
// type UserWxConfig = Pick<WxConfig, 'debug' | 'jsApiList'>
// // 后端接口返回的4个config字段
// type ApiWxConfig = Pick<WxConfig, 'appId' | 'nonceStr' | 'signature' | 'timestamp'>
export function registerWx(options: Options, urls?: string) {
  urls = urls || location.href
  // 接口获取config
  getWxConfig({ urls })
    .then((res) => {
      const config = res.data.data as WxConfig
      // 微信配置
      configWx(config)
      onWxError().then((res) => {
        console.log('wx.error', res)
      })
      onWxReady().then(() => {
        // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
        ;(wx as any).updateAppMessageShareData({
          title: options.title, // 分享标题
          desc: options.desc, // 分享描述
          link: options.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: options.imgUrl, // 分享图标
          success:
            options.messageShareSuccess ||
            function () {
              console.log('wx.updateAppMessageShareData 成功')
            },
        })
        // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
        ;(wx as any).updateTimelineShareData({
          title: options.title, // 分享标题
          link: options.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: options.imgUrl, // 分享图标
          success:
            options.timelineShareSuccess ||
            function () {
              console.log('wx.updateTimelineShareData 成功')
            },
        })
      })
    })
    .catch(() => {})
}
