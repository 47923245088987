
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Popup, Button, Toast } from 'vant'
import Exchange from './components/exchange.vue'
import {
  getLotteryInfo,
  getRecentWinRecords,
  getPrizeList,
  lotteryShareGetChance,
} from '@/api/lottery'
import { ResponseCode } from '@/constants/http'
import { registerWx } from '@/utils/wxApi'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.less'
import SwiperCore, { Autoplay } from 'swiper/core'
SwiperCore.use([Autoplay])

export default defineComponent({
  name: 'Lottery',

  components: {
    [Popup.name]: Popup,
    [Button.name]: Button,
    Exchange,
    Swiper,
    SwiperSlide,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    const ready = ref(false)
    // 是否活动不存在
    const notExist = ref(false)
    // 活动是否过期
    const overdue = ref(false)
    // 活动id
    const configId = ref('')
    // 类型 1:翻牌 2: 转盘
    const type = ref(1) // 1: FlipCard 2: LotteryWheel
    // 活动名称
    const luckyDrawName = ref('')
    // 活动副标题
    const luckyDrawSlogen = ref('')
    // banner图片
    const bannerImg = ref('')
    // 活动规则
    const activityRule = ref('')
    // 客服二维码
    const customerServiceQrCode = ref('')
    // 中奖记录
    const records = ref<Recordable[]>([])
    // 1后台设置了分享不增加抽奖次数 0相反
    const noShare = ref(0)
    // 奖品池
    const prizePool = ref<Recordable[]>([])
    // 联系客服 弹窗
    const contactVisible = ref(false)
    // 活动规则 弹窗
    const rulesVisible = ref(false)
    // 奖池 弹窗
    const poolVisible = ref(false)
    // 中奖 弹窗
    const winVisible = ref(false)
    // 抽中奖品名称
    const winName = ref('')
    // 抽中奖品类型
    const winType = ref(0)
    const exchangeRef = ref()
    // 抽中奖品在我的奖品列表记录中的主键id
    const luckyDrawWinningRecordLogId = ref(0)
    // flipCard组件的引用
    const flipCardRef = ref('')
    // lotteryWheel组件的引用
    const lotteryWheelRef = ref('')

    // 获取信息
    const fetchInfo = () => {
      const _type = 2 // 1PC端 2移动端
      return getLotteryInfo(route.query.code as string, _type)
        .then((res) => {
          const { data } = res.data
          ready.value = true
          overdue.value = data.overdue
          configId.value = data.luckyDrawConfigId
          luckyDrawName.value = data.luckyDrawName
          luckyDrawSlogen.value = data.luckyDrawSlogen
          type.value = data.uiType
          bannerImg.value = data.bannerImg
          activityRule.value = data.activityRule
          customerServiceQrCode.value = data.customerServiceQrCode
          noShare.value = data.noShare
          return data
        })
        .catch((error) => {
          if (error?.data?.code === ResponseCode.ActivityNotExist) {
            // 活动不存在
            notExist.value = true
          }
        })
    }

    const getRecords = () => {
      getRecentWinRecords(configId.value)
        .then((res) => {
          const data = res.data.data as Recordable[]
          records.value = data.map((item) => {
            return {
              name: item.mobile,
              reward: item.prizeName,
            }
          })
          // .slice(0, 3)
        })
        .catch(() => {})
    }

    const getPrizePoll = () => {
      getPrizeList(route.query.code as string).then((res) => {
        const data = res.data.data as Recordable[]
        prizePool.value = data.map((item) => {
          return {
            name: item.prizeName,
            num: item.prizeNumber,
          }
        })
      })
    }

    const goMine = () => {
      router.push(`/lottery/mine?code=${route.query.code}`)
    }

    const handleExchange = () => {
      winVisible.value = false
      console.log(exchangeRef.value.visible)
      if (winType.value === 3) {
        // 实物奖品，走兑换流程
        exchangeRef.value.visible = true
      } else {
        // 虚拟奖品，弹客服二维码
        contactVisible.value = true
      }
    }

    // 抽奖抽出结果
    const onLotteryOpened = (payload: {
      luckyDrawWinningRecordLogId: number
      id: number
      type: number
      name: string
    }) => {
      getRecords()
      const { type, name } = payload
      console.log('payload', payload)
      luckyDrawWinningRecordLogId.value = payload.luckyDrawWinningRecordLogId
      winType.value = Number(type)
      winName.value = name
      if (Number(type) === 5) {
        notWinTip()
      } else {
        winVisible.value = true
      }
    }

    // 未中奖提示
    const notWinTip = () => {
      Toast('很遗憾这次没有中奖，之后再来试试吧！')
    }

    // 分享增加抽奖机会
    const handleShare = () => {
      lotteryShareGetChance(route.query.code as string)
        .then(() => {
          Toast(
            noShare.value == 1
              ? '点击右上角按钮分享，与朋友一起参与吧'
              : '请点击右上角分享按钮，可获得额外抽奖次数'
          )
          // 调用抽奖组件的获取信息接口，刷新抽奖机会
          console.log(type.value)
          if (type.value == 1) {
            console.log(1, flipCardRef.value, (flipCardRef.value as any).fetchInfo)
            ;(flipCardRef.value as any).fetchInfo()
          } else {
            ;(lotteryWheelRef.value as any).fetchInfo()
          }
        })
        .catch(() => {})
    }

    onMounted(() => {
      fetchInfo().then(() => {
        getRecords()
        registerWx({
          title: luckyDrawName.value,
          link: location.href,
          desc: luckyDrawSlogen.value,
          imgUrl: bannerImg.value,
          messageShareSuccess() {
            console.log('messageShareSuccess')
          },
          timelineShareSuccess() {
            console.log('timelineShareSuccess')
          },
        })
      })
      getPrizePoll()
    })

    return {
      ready,
      notExist,
      overdue,
      configId,
      type,
      luckyDrawName,
      luckyDrawSlogen,
      bannerImg,
      activityRule,
      customerServiceQrCode,
      records,
      noShare,
      prizePool,
      contactVisible,
      rulesVisible,
      poolVisible,
      winVisible,
      winName,
      winType,
      exchangeRef,
      luckyDrawWinningRecordLogId,
      flipCardRef,
      lotteryWheelRef,
      getRecords,
      getPrizePoll,
      goMine,
      handleExchange,
      onLotteryOpened,
      handleShare,
    }
  },
})
