<template>
  <template v-if="!notExist">
    <div v-if="overdue" class="empty-wrapper">
      <empty message="当前活动已过期~" />
    </div>
    <div v-else class="page-lottery">
      <div class="banner">
        <img :src="bannerImg" alt="" />
      </div>

      <template v-if="ready">
        <FlipCard v-if="type == 1" ref="flipCardRef" @opened="onLotteryOpened" />
        <LotteryWheel v-else ref="lotteryWheelRef" @opened="onLotteryOpened" />
      </template>

      <div class="records">
        <div class="records-title">
          <img :src="require('./gift.png')" alt="" class="records-title__img" />
          <span>最新中奖记录</span>
        </div>
        <div class="records-cont">
          <div v-if="!records.length" class="records-empty">首个大奖非你莫属，快来抽奖吧</div>
          <!-- <div v-else class="records-list">
            <div v-for="(item, index) in records" :key="index" class="records-item">
              {{ item.name }} 抽中 {{ item.reward }}
            </div>
          </div> -->
          <div class="records-swiper-container">
            <swiper
              :direction="'vertical'"
              :slides-per-view="3"
              :autoplay="{
                delay: 1000,
                disableOnInteraction: false,
              }"
              :loop="true"
              class="records-swiper"
            >
              <swiper-slide
                v-for="(item, index) in records"
                :key="index"
                class="records-swiper-slide"
                >{{ item.name }} 抽中 {{ item.reward }}</swiper-slide
              >
            </swiper>
          </div>
        </div>
      </div>

      <div class="fixed-nav">
        <!-- <div class="fixed-nav-item" @click="handleShare">
          <font-icon icon-name="share-outlined" class="fixed-nav-item__icon"></font-icon>
          <div class="fixed-nav-item__title">分享</div>
        </div> -->
        <!-- <div class="fixed-nav-item" @click="poolVisible = true">
          <font-icon icon-name="prize-outlined" class="fixed-nav-item__icon"></font-icon>
          <div class="fixed-nav-item__title">奖品</div>
        </div> -->
        <a
          class="fixed-nav-item"
          :href="`/app/#/lottery/mine?code=${$route.query.code}`"
          @click="goMine"
        >
          <font-icon icon-name="exchange-outlined" class="fixed-nav-item__icon"></font-icon>
          <div class="fixed-nav-item__title">兑奖</div>
        </a>
        <div class="fixed-nav-item" @click="rulesVisible = true">
          <font-icon icon-name="edit-outlined" class="fixed-nav-item__icon"></font-icon>
          <div class="fixed-nav-item__title">规则</div>
        </div>
        <div class="fixed-nav-item" @click="contactVisible = true">
          <font-icon icon-name="chat-outlined" class="fixed-nav-item__icon"></font-icon>
          <div class="fixed-nav-item__title">客服</div>
        </div>
      </div>

      <!-- 联系客服 -->
      <van-popup v-model:show="contactVisible">
        <div class="contact-popup common-popup">
          <div class="popup-title">
            <span>联系客服</span>
          </div>
          <div class="contact-title">为了保证奖品有效发放，请联系客服确认</div>
          <img :src="customerServiceQrCode" alt="" class="contact-qr" />
          <div class="contact-tip">微信内扫码添加客服</div>
        </div>
      </van-popup>

      <!-- 活动规则 -->
      <van-popup v-model:show="rulesVisible">
        <div class="rules-popup common-popup">
          <div class="popup-title">
            <span>活动规则</span>
          </div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="rules-cont" v-html="activityRule"></div>
        </div>
      </van-popup>

      <!-- 奖品 -->
      <van-popup v-model:show="poolVisible">
        <div class="pool-popup common-popup">
          <div class="popup-title">
            <span>奖品设置</span>
          </div>
          <div v-for="(item, index) in prizePool" :key="index" class="pool-item">
            <div class="pool-item__name">{{ index + 1 }}、{{ item.name }}</div>
            <div class="pool-item__num">{{ item.num }}个</div>
          </div>
        </div>
      </van-popup>

      <!-- 中奖 -->
      <van-popup v-model:show="winVisible">
        <div class="win-popup common-popup">
          <img :src="require('./prize.png')" alt="" />
          <div class="win-popup-msg">恭喜您获得</div>
          <div class="win-popup-goods">{{ winName }}</div>
          <!-- <div class="btn">点击兑奖</div> -->
          <van-button color="#f34141" class="btn" @click="handleExchange">点击兑奖</van-button>
        </div>
      </van-popup>

      <Exchange
        :id="luckyDrawWinningRecordLogId"
        ref="exchangeRef"
        :name="winName"
        @done="contactVisible = true"
      />
    </div>
  </template>
  <div v-else class="empty-wrapper">
    <empty message="当前活动不存在~" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Popup, Button, Toast } from 'vant'
import Exchange from './components/exchange.vue'
import {
  getLotteryInfo,
  getRecentWinRecords,
  getPrizeList,
  lotteryShareGetChance,
} from '@/api/lottery'
import { ResponseCode } from '@/constants/http'
import { registerWx } from '@/utils/wxApi'

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.less'
import SwiperCore, { Autoplay } from 'swiper/core'
SwiperCore.use([Autoplay])

export default defineComponent({
  name: 'Lottery',

  components: {
    [Popup.name]: Popup,
    [Button.name]: Button,
    Exchange,
    Swiper,
    SwiperSlide,
  },

  setup() {
    const router = useRouter()
    const route = useRoute()
    const ready = ref(false)
    // 是否活动不存在
    const notExist = ref(false)
    // 活动是否过期
    const overdue = ref(false)
    // 活动id
    const configId = ref('')
    // 类型 1:翻牌 2: 转盘
    const type = ref(1) // 1: FlipCard 2: LotteryWheel
    // 活动名称
    const luckyDrawName = ref('')
    // 活动副标题
    const luckyDrawSlogen = ref('')
    // banner图片
    const bannerImg = ref('')
    // 活动规则
    const activityRule = ref('')
    // 客服二维码
    const customerServiceQrCode = ref('')
    // 中奖记录
    const records = ref<Recordable[]>([])
    // 1后台设置了分享不增加抽奖次数 0相反
    const noShare = ref(0)
    // 奖品池
    const prizePool = ref<Recordable[]>([])
    // 联系客服 弹窗
    const contactVisible = ref(false)
    // 活动规则 弹窗
    const rulesVisible = ref(false)
    // 奖池 弹窗
    const poolVisible = ref(false)
    // 中奖 弹窗
    const winVisible = ref(false)
    // 抽中奖品名称
    const winName = ref('')
    // 抽中奖品类型
    const winType = ref(0)
    const exchangeRef = ref()
    // 抽中奖品在我的奖品列表记录中的主键id
    const luckyDrawWinningRecordLogId = ref(0)
    // flipCard组件的引用
    const flipCardRef = ref('')
    // lotteryWheel组件的引用
    const lotteryWheelRef = ref('')

    // 获取信息
    const fetchInfo = () => {
      const _type = 2 // 1PC端 2移动端
      return getLotteryInfo(route.query.code as string, _type)
        .then((res) => {
          const { data } = res.data
          ready.value = true
          overdue.value = data.overdue
          configId.value = data.luckyDrawConfigId
          luckyDrawName.value = data.luckyDrawName
          luckyDrawSlogen.value = data.luckyDrawSlogen
          type.value = data.uiType
          bannerImg.value = data.bannerImg
          activityRule.value = data.activityRule
          customerServiceQrCode.value = data.customerServiceQrCode
          noShare.value = data.noShare
          return data
        })
        .catch((error) => {
          if (error?.data?.code === ResponseCode.ActivityNotExist) {
            // 活动不存在
            notExist.value = true
          }
        })
    }

    const getRecords = () => {
      getRecentWinRecords(configId.value)
        .then((res) => {
          const data = res.data.data as Recordable[]
          records.value = data.map((item) => {
            return {
              name: item.mobile,
              reward: item.prizeName,
            }
          })
          // .slice(0, 3)
        })
        .catch(() => {})
    }

    const getPrizePoll = () => {
      getPrizeList(route.query.code as string).then((res) => {
        const data = res.data.data as Recordable[]
        prizePool.value = data.map((item) => {
          return {
            name: item.prizeName,
            num: item.prizeNumber,
          }
        })
      })
    }

    const goMine = () => {
      router.push(`/lottery/mine?code=${route.query.code}`)
    }

    const handleExchange = () => {
      winVisible.value = false
      console.log(exchangeRef.value.visible)
      if (winType.value === 3) {
        // 实物奖品，走兑换流程
        exchangeRef.value.visible = true
      } else {
        // 虚拟奖品，弹客服二维码
        contactVisible.value = true
      }
    }

    // 抽奖抽出结果
    const onLotteryOpened = (payload: {
      luckyDrawWinningRecordLogId: number
      id: number
      type: number
      name: string
    }) => {
      getRecords()
      const { type, name } = payload
      console.log('payload', payload)
      luckyDrawWinningRecordLogId.value = payload.luckyDrawWinningRecordLogId
      winType.value = Number(type)
      winName.value = name
      if (Number(type) === 5) {
        notWinTip()
      } else {
        winVisible.value = true
      }
    }

    // 未中奖提示
    const notWinTip = () => {
      Toast('很遗憾这次没有中奖，之后再来试试吧！')
    }

    // 分享增加抽奖机会
    const handleShare = () => {
      lotteryShareGetChance(route.query.code as string)
        .then(() => {
          Toast(
            noShare.value == 1
              ? '点击右上角按钮分享，与朋友一起参与吧'
              : '请点击右上角分享按钮，可获得额外抽奖次数'
          )
          // 调用抽奖组件的获取信息接口，刷新抽奖机会
          console.log(type.value)
          if (type.value == 1) {
            console.log(1, flipCardRef.value, (flipCardRef.value as any).fetchInfo)
            ;(flipCardRef.value as any).fetchInfo()
          } else {
            ;(lotteryWheelRef.value as any).fetchInfo()
          }
        })
        .catch(() => {})
    }

    onMounted(() => {
      fetchInfo().then(() => {
        getRecords()
        registerWx({
          title: luckyDrawName.value,
          link: location.href,
          desc: luckyDrawSlogen.value,
          imgUrl: bannerImg.value,
          messageShareSuccess() {
            console.log('messageShareSuccess')
          },
          timelineShareSuccess() {
            console.log('timelineShareSuccess')
          },
        })
      })
      getPrizePoll()
    })

    return {
      ready,
      notExist,
      overdue,
      configId,
      type,
      luckyDrawName,
      luckyDrawSlogen,
      bannerImg,
      activityRule,
      customerServiceQrCode,
      records,
      noShare,
      prizePool,
      contactVisible,
      rulesVisible,
      poolVisible,
      winVisible,
      winName,
      winType,
      exchangeRef,
      luckyDrawWinningRecordLogId,
      flipCardRef,
      lotteryWheelRef,
      getRecords,
      getPrizePoll,
      goMine,
      handleExchange,
      onLotteryOpened,
      handleShare,
    }
  },
})
</script>

<style lang="less" scoped>
.page-lottery {
  min-height: 100vh;
  padding: 20px 0;
  background: #f9f9f9;
  overflow-x: hidden;
  .banner {
    width: 690px;
    height: 330px;
    border-radius: 10px;
    margin: 0 auto 20px;
    img {
      width: 690px;
      height: 330px;
      border-radius: 10px;
    }
  }

  .records {
    width: 690px;
    margin: 20px auto;
    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 94px;
      border-radius: 24px 24px 0px 0px;
      font-size: 32px;
      color: #fff;
      background: linear-gradient(270deg, #fc5517 0%, #fea252 100%);
      &__img {
        width: 36px;
        height: 36px;
        margin-right: 10px;
      }
    }
    &-cont {
      border-radius: 0px 0px 24px 24px;
      background: #ffffff;
    }
    &-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 110px;
    }
    &-list {
      padding: 30px;
    }
    &-item {
      line-height: 56px;
      font-size: 24px;
    }
  }

  .records-swiper-container {
    padding: 30px;
  }
  .records-swiper {
    height: 168px;
  }
  .records-swiper-slide {
    line-height: 56px;
    height: 56px;
    font-size: 24px;
  }

  .fixed-nav {
    position: fixed;
    // right: 18px;
    right: 10px;
    bottom: 224px;
    transform: scale(0.8);
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background: #fff;
      color: #999;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px;
      &:not(:last-child) {
        // margin-bottom: 10px;
        margin-bottom: 16px;
      }
      &__icon {
        font-size: 32px;
      }
      &__title {
        font-size: 24px;
      }
    }
  }
}
.popup-title {
  padding-bottom: 30px;
  text-align: center;
  span {
    position: relative;
    display: inline-block;
    padding: 0 62px;
    font-size: 30px;
    color: #333;
    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      top: 50%;
      width: 40px;
      height: 1px;
      background: #979797;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
}
.common-popup {
  width: 580px;
  padding: 30px;
  border-radius: 8px;
  background: #fff;
  text-align: center;
}
// 联系客服弹窗
.contact-popup {
  .contact-title {
    margin-bottom: 38px;
    line-height: 40px;
    font-size: 28px;
    color: #666;
  }
  .contact-qr {
    width: 218px;
    height: 218px;
    background: #d8d8d8;
  }
  .contact-tip {
    margin-top: 14px;
    line-height: 28px;
    font-size: 20px;
    text-align: center;
    color: #666666;
  }
}
// 规则弹窗
.rules-popup {
  .rules-cont {
    width: 500px;
    height: 714px;
    margin: 0 auto;
    overflow-y: auto;
  }
}
// 奖品设置弹窗
.pool-popup {
  .pool-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 46px;
    font-size: 24px;
    color: #999;
  }
}
// 中奖弹窗
.win-popup {
  text-align: center;
  color: #f34141;
  img {
    width: 272px;
    height: 185px;
  }
  &-msg {
    margin-top: 10px;
    font-size: 26px;
  }
  &-goods {
    margin-top: 10px;
    font-size: 32px;
  }
  .btn {
    margin: 20px auto 0;
    width: 250px;
    height: 70px;
    border-radius: 6px;
  }
}
.empty-wrapper {
  height: 100vh;
  padding-top: 126px;
  background: #fff;
}
</style>

<style lang="less">
.page-lottery {
  // 去除popup自带的背景色
  .van-popup {
    background: transparent;
  }

  // 修复 swiper组件的bug loop模式下 被复制的slide标签会被swiper组件内部打上自己的scope，导致scoped style失效
  .records-swiper-slide {
    line-height: 56px;
    height: 56px;
    font-size: 24px;
  }
}
</style>
